<template>
  <article class="container-fluid">
    <h1 class="main-title">{{ title }}</h1>

    <div v-show="showWizard">
      <downtime-reason-wizard :parent-category-id="parentCategoryId" :initial-reason="reason" />
    </div>
  </article>
</template>

<script>
import DowntimeReasonWizard from "../components/downtimereason/DowntimeReasonWizard";
import DowntimeReasonService from "@/components/downtimereason/DowntimeReasonService";
import ErrorHandling from "@/components/ErrorHandling";
import RouteService from "@/router/RouteService";

export default {
  components: {
    DowntimeReasonWizard,
  },
  data() {
    return {
      showWizard: false,
      isCreation: true,
      reason: null,
      parentCategoryId: "",
    };
  },
  computed: {
    title() {
      return this.isCreation
        ? this.$t("downtimeReason.wizard.reasonCreation.title")
        : this.$t("downtimeReason.wizard.reasonEdition.title");
    },
  },
  methods: {
    async fetchReason(reasonId) {
      DowntimeReasonService.getDowntimeReasonById(reasonId)
        .then((response) => this.handleReasonRetrievalResponse(response, reasonId))
        .catch((error) => this.handleReasonRetrievalError(error.response));
    },
    handleReasonRetrievalResponse(httpResponse) {
      if (httpResponse.status === 200) {
        this.reason = httpResponse.data;
        this.parentCategoryId = this.reason.category_id;
        this.showWizard = true;
      }
    },
    handleReasonRetrievalError(httpResponse) {
      this.showOperationError(ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage));
      this.$router.push(RouteService.toDowntimeReasons());
    },
    getErrorMessage(code) {
      return this.$t("common.errors.default", { code: code });
    },
  },

  async mounted() {
    this.reasonId = this.$route.query.reasonId;
    this.parentCategoryId = this.$route.query.parentCategoryId ? this.$route.query.parentCategoryId : "";
    if (this.reasonId) {
      this.isCreation = false;
      await this.fetchReason(this.reasonId);
    } else {
      this.isCreation = true;
      this.showWizard = true;
    }
  },
};
</script>
